import React from 'react';
import './App.css';

function Countdown() {
  const [days, setDays] = React.useState('00');
  const [hours, setHours] = React.useState('00');
  const [minutes, setMinutes] = React.useState('00');
  const [seconds, setSeconds] = React.useState('00');

  React.useEffect(() => {
    const countDownDate = new Date("Feb 15, 2025 10:00:00").getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const d = Math.floor(distance / (1000 * 60 * 60 * 24));
      const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const s = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(d < 10 ? `0${d}` : d);
      setHours(h < 10 ? `0${h}` : h);
      setMinutes(m < 10 ? `0${m}` : m);
      setSeconds(s < 10 ? `0${s}` : s);
      if (distance < 0) {
        clearInterval(x);
        setDays('00');
        setHours('00');
        setMinutes('00');
        setSeconds('00');
      }
    }, 1000);
    return () => clearInterval(x);
  });

  return (
    <div id="countdown">
      <div className='cdBox' id="cdDays">
        <span className="cdValues" id="cdDaysValue">{days}</span>
        <span className='cdText' id="cdDaysText">Dias</span>
      </div>
      <div className='cdBox' id="cdHours">
        <span className="cdValues" id="cdHoursValue">{hours}</span>
        <span className='cdText' id="cdHoursText">Horas</span>
      </div>
      <div className='cdBox' id="cdMinutes">
        <span className="cdValues" id="cdMinutesValue">{minutes}</span>
        <span className='cdText' id="cdMinutesText">Minutos</span>
      </div>
      <div className='cdBox' id="cdSeconds">
        <span className="cdValues" id="cdSecondsValue">{seconds}</span>
        <span className='cdText' id="cdSecondsText">Segundos</span>
      </div>
    </div>
  )
}

function turnOnLights() {
  const lights = document.getElementsByClassName('lightEffect');
  for (let i = 0; i < lights.length; i++) {
    lights[i].style.opacity = '1';
  }
}

function showLogo() {
  const logo = document.getElementById('logo');
  setTimeout(() => {
    logo.style.opacity = '1';
  }, 2050);
}

function showCountdown() {
  const day = document.getElementById('cdDays');
  const hour = document.getElementById('cdHours');
  const minute = document.getElementById('cdMinutes');
  const second = document.getElementById('cdSeconds');
  setTimeout(() => {
    day.style.opacity = '1';
  }, 2500);

  setTimeout(() => {
    hour.style.opacity = '1';
  }, 2750);

  setTimeout(() => {
    minute.style.opacity = '1';
  }, 2900);

  setTimeout(() => {
    second.style.opacity = '1';
  }, 3050);
}

function Apresentation() {
  React.useEffect(() => {
    turnOnLights();
    showLogo();
    showCountdown();
  }, []);

  return (
    <>
      <div className="shader" id="lightEffectGroup">
        <div className="lightEffect" id="lightLeft" />
        <div className="lightEffect" id="lightRight" />
      </div>
      <div className='shader' id="blurEffect" />
      <div id="principal">
        <div id="pContent">
          <img src="logoApresentation.png" alt="DaKasa LTDA" id="logo"/>
          <Countdown />
        </div>
      </div>
    </>
  )
} 

function App() {
  return (
    <div className="App">
      <Apresentation />
    </div>
  );
}

export default App;
